@import "inc/variables";
@import "inc/functions";

@font-face {
  font-family: "Neue Haas Grotesk";
  src: url(static("fonts/NHaasGroteskDSPro-55Rg.woff2")) format("woff2"),
    url(static("fonts/NHaasGroteskDSPro-55Rg.woff")) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Neue Haas Grotesk";
  src: url(static("fonts/NHaasGroteskDSPro-65Md.woff2")) format("woff2"),
    url(static("fonts/NHaasGroteskDSPro-65Md.woff")) format("woff");
  font-weight: bold;
  font-style: normal;
}
